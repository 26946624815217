import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown, Button } from "react-bootstrap";
import "./Header.css";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    aboutUs: false,
    ourWork: false,
  });

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const toggleDropdown = (dropdown) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const closeDropdowns = () => {
    setDropdownOpen({
      aboutUs: false,
      ourWork: false,
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar expand="lg" className={`header ${scrolled ? "scrolled" : ""}`}>
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={require("../assets/logo3.png")} alt="Logo" className="header-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav-dropdown" />
        <Navbar.Collapse id="navbar-nav-dropdown">
          <Nav className="ms-auto">
            <Nav.Link href="/home">Home</Nav.Link>
            <NavDropdown
              title="About Us"
              id="about-us-dropdown"
              className="custom-dropdown"
              show={dropdownOpen.aboutUs}
              onMouseEnter={() => toggleDropdown("aboutUs")}
              onMouseLeave={closeDropdowns}
            >
              <NavDropdown.Item target="_blank" href="https://drive.google.com/file/d/18rRTmGkis36WB1ZCcbHczH2Upvr2E1sc/view?usp=sharing">Know more</NavDropdown.Item>
              <NavDropdown.Item href="/home">Impact</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://docs.google.com/forms/d/e/1FAIpQLSeU-QOEEKbIGusVKHsydW9BtBFQOGdxlu0n0uBWYTESTZnGiw/viewform?pli=1">Join Us</Nav.Link>
            <Nav.Link href="/gallery">Gallery</Nav.Link>
            <NavDropdown
              title="Projects"
              id="our-work-dropdown"
              className="custom-dropdown"
              show={dropdownOpen.ourWork}
              onMouseEnter={() => toggleDropdown("ourWork")}
              onMouseLeave={closeDropdowns}
            >
              <NavDropdown.Item href="#">Amazing Shala</NavDropdown.Item>
              <NavDropdown.Item href="#">Women Empowerment</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>
          <Button className="btn header-donate-btn btn-outline-light ms-auto" href="/donate" type="button">
            Donate Now
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
